import { useCallback } from "react";

const useProjectJson = (setProjectNow) => {
  const setProjectJson = useCallback(
    (editor, value) => {
      try {
        const jsonInput = typeof value === "string" ? JSON.parse(value) : value;
        setProjectNow((prevProjectNow) => ({ ...prevProjectNow, json: jsonInput }));
        const pages = editor.Pages.getAll();
        pages.forEach((page) => editor.Pages.remove(page.id));
        editor.Pages.clear();
        /* jsonInput.forEach((pageData) => {
          const page = editor.Pages.add({ name: pageData.name }, true);
          editor.Pages.select(page);
          editor.setComponents(pageData.pages);
          editor.setStyle(pageData.css);
        });
        return true; */

        jsonInput.forEach((pageData) => {
          const page = editor.Pages.add({ name: pageData.name, id: pageData.id }, { silent: true });
          editor.Pages.select(page);
          const wrapper = editor.DomComponents.getWrapper();

          if (pageData.pages && pageData.pages.attributes) {
            wrapper.setAttributes(pageData.pages.attributes);
          }
          /*   if (pageData.pages && pageData.pages.components) {
            wrapper.append(pageData.pages.components);
            console.log(`Page ${pageData.name} components added:`, pageData.pages.components);
          } else {
            console.warn("No components found for page:", pageData.name);
          } */
          if (pageData.pages) {
            wrapper.append(pageData.pages);
            //  console.log(`Page ${pageData.name} components added:`, pageData.pages);
          } else {
            console.warn("No components found for page:", pageData.name);
          }
          if (pageData.css) {
            editor.setStyle(pageData.css);
            // console.log(`Page ${pageData.name} styles added:`, pageData.css);
          }
        });
        const firstPage = editor.Pages.getAll()[0];
        if (firstPage) {
          editor.Pages.select(firstPage.id);
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [setProjectNow]
  );

  return { setProjectJson };
};

export default useProjectJson;
